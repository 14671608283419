/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:818a03eb-e225-4aef-b59e-22b2c38efe6a",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_W6rkgHUdE",
    "aws_user_pools_web_client_id": "2djmdnr961rdrmnb8sn8p0s8po",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://mekzd0ku3c.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "serverlessapi",
            "endpoint": "https://9jlydgabu9.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "whattastorage03704-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
