
import { gql } from "@apollo/client";

export const LIST_LINE_JOBS = gql`
  query ListLineJobs($where: line_job_bool_exp!, $pageSize: Int, $offset: Int, $order: line_job_order_by!) {
    line_job_aggregate(where: $where) {
      aggregate {
        total: count
      }
    }
    line_job(where: $where, limit: $pageSize, offset: $offset, order_by: [$order]) {
      id
      description
      code
      customer {
        name
      }
      date
      shift
      items_qty
      items_estimated_qty
      workers_estimated_qty
      workers_qty
      workers_active
      state
      item {
        id
        number
        material_price
        sales_price
        description
        account {
            id
            name
          }
      }
      line {
        number
        location {
          name
        }
        is_add_on
      }
      user {
        first_name
        last_name
      }
      timelogs_aggregate {
        aggregate {
          sum {
            calc_time
            calc_cost
          }
        }
      }
    }
  }
`;

export const ADD_LINE_JOB = gql`
  mutation AddLineJob($object: line_job_insert_input!, $item_id: Int!, $item: item_set_input) {
    insert_line_job_one (object: $object) {
      id
    }
    update_item_by_pk(pk_columns: {id: $item_id}, _set: $item) {
      id
      description
      material_price
      number
      sales_price
      customer_id
    }
  }
`;

export const ADD_MISC_JOB = gql`
  mutation AddMiscJob($object: line_job_insert_input!) {
    insert_line_job_one (object: $object) {
      id
    }
  }
`;

export const GET_LINE_JOB_BY_ID = gql`
  query GetLineJobByID($id: Int!) {
    line_job_by_pk(id: $id) {
      id
      line_id
      leader_id
      customer_id
      item_id
      shift
      items_qty
      date
      description
      workers_estimated_qty
      workers_qty
      workers_active
      code
      state
      line {
        is_misc
        location {
          id
        }
      }
      start_time
      total_time
      total_regular_cost
      total_overtime
      total_overtime_cost
      items_qty
      items_estimated_qty
      pieces
      cups
      lids
      total_parts
      line_job_equipments {
        equipment {
          id
          name
        }
      }
      line_job_job_types {
        job_type {
          id
          name
        }
      }
      line_job_pallets {
        pallet {
          id
          description
        }
      }
    }
  }
`;

export const GET_FULL_LINE_JOB_INFO_BY_ID = gql`
  query GetLineJobWithTimelogsByID($id: Int!,  $date: date) {
    line_job_by_pk(id: $id) {
      id
      line_id
      leader_id
      user {
        id
        worker {
          id
          position_id
          rate
          markup
          deleted_at
          worker_location_rates_aggregate {
            nodes {
              base_rate
              differential
              markup
              id
              location_id
            }
          }
        }
      }
      customer_id
      item_id
      shift
      items_qty
      items_estimated_qty
      date
      description
      workers_estimated_qty
      code
      line {
        is_misc
        number
        is_misc
        location {
          id
        }
      }
      state
      workers_qty
      workers_active
      item {
        number
        sales_price
        material_price
        description
      }
      customer {
        id
        name
      }
      start_time
      stop_time
      job_start
      checkout_time
      lunch_time
      line_job_equipments {
        equipment {
          id
          name
        }
      }
      line_job_job_types {
        job_type {
          id
          name
        }
      }
      line_job_pallets {
        pallet {
          id
          description
        }
      }
      notes
      unitByUnit {
        comment
        value
      }
      line_job_workers {
        worker {
          first_name
          last_name
          unique_access_id
          id
          picture
          position {
            id
            comment
            comment_es
          }
          agency {
            id
            name
          }
          timelogs(where: {deleted_at: {_is_null: true}, date: {_gte: $date}}, order_by: {id: asc}) {
            id
            line_id
            line_job_id
            date
            line {
              number
            }
            position {
              comment
              comment_es
              id
            }
            shift
            start_time
            start_type
            state
            stop_time
            stop_type
            worker_id
            rate
            markup
            lunch_time
            checkoutReasonByCheckoutReason {
              comment
              value
            }
            checkout_comment
          }
        }
      }
      pieces
      cups
      lids
    }
  }
`;

export const UPDATE_LINE_JOB = gql`
  mutation UpdateLineJob($id: Int!, $object: line_job_set_input, $item_id: Int!, $item: item_set_input, $equipment: [line_job_equipment_insert_input!]!, $job_type: [line_job_job_type_insert_input!]!, $pallet: [line_job_pallet_insert_input!]!) {
    update_line_job_by_pk(pk_columns: {id: $id}, _set: $object) {
      id
      line_id
      leader_id
      customer_id
      item_id
      shift
      items_qty
      date
    }
    update_item_by_pk(pk_columns: {id: $item_id}, _set: $item) {
      id
      description
      material_price
      number
      sales_price
      customer_id
    }
    delete_line_job_equipment(where: {line_job_id: {_eq: $id}}) {
      affected_rows
    }
    insert_line_job_equipment(objects: $equipment) {
      affected_rows
    }
    delete_line_job_job_type(where: {line_job_id: {_eq: $id}}) {
      affected_rows
    }
    insert_line_job_job_type(objects: $job_type) {
      affected_rows
    }
    delete_line_job_pallet(where: {line_job_id: {_eq: $id}}) {
      affected_rows
    }
    insert_line_job_pallet(objects: $pallet) {
      affected_rows
    }
  }
`;

export const UPDATE_MISC_JOB = gql`
  mutation UpdateMiscJob($id: Int!, $object: line_job_set_input) {
    update_line_job_by_pk(pk_columns: {id: $id}, _set: $object) {
      id
      line_id
      leader_id
      customer_id
      item_id
      shift
      items_qty
      date
    }
  }
`;

export const DELETE_LINE_JOB = gql`
  mutation DeleteJobLine($id: Int!) {
    update_line_job_by_pk(pk_columns: {id: $id}, _set: {deleted_at: "now()"}) {
      id
      deleted_at
    }
  }
`;

export const UPDATE_LINE_JOB_STATUS = gql`
  mutation UpdateLineJobStatus($id: Int!, $object: line_job_set_input, $equipment: [line_job_equipment_insert_input!]!, $job_type: [line_job_job_type_insert_input!]!, $pallet: [line_job_pallet_insert_input!]!) {
    update_line_job_by_pk(pk_columns: {id: $id}, _set: $object) {
      id
      line_id
      line {
        is_misc
        state
      }
      leader_id
      customer_id
      item_id
      shift
      items_qty
      date
      state
      checkout_time
      stop_time
      lunch_time
    }
    delete_line_job_equipment(where: {line_job_id: {_eq: $id}}) {
      affected_rows
    }
    insert_line_job_equipment(objects: $equipment) {
      affected_rows
    }
    delete_line_job_job_type(where: {line_job_id: {_eq: $id}}) {
      affected_rows
    }
    insert_line_job_job_type(objects: $job_type) {
      affected_rows
    }
    delete_line_job_pallet(where: {line_job_id: {_eq: $id}}) {
      affected_rows
    }
    insert_line_job_pallet(objects: $pallet) {
      affected_rows
    }
  }
`;

export const LINE_JOB_BULK_UPDATE = gql`
  mutation LineJobBulkUpdate($jobEquipmentIds: [Int!], $jobJobtypeIds: [Int!], $jobPalletIds: [Int!], $equipment: [line_job_equipment_insert_input!]!, $job_type: [line_job_job_type_insert_input!]!, $pallet: [line_job_pallet_insert_input!]!) {
    delete_line_job_equipment(where: {line_job_id: {_in: $jobEquipmentIds}}) {
      affected_rows
    }
    insert_line_job_equipment(objects: $equipment) {
      affected_rows
    }
    delete_line_job_job_type(where: {line_job_id: {_in: $jobJobtypeIds}}) {
      affected_rows
    }
    insert_line_job_job_type(objects: $job_type) {
      affected_rows
    }
    delete_line_job_pallet(where: {line_job_id: {_in: $jobPalletIds}}) {
      affected_rows
    }
    insert_line_job_pallet(objects: $pallet) {
      affected_rows
    }
  }
`;

export const LIST_LINE_JOBS_EMBEDDER = gql`
query ListLineJobsForEmbeddedTable($date: date) {
  line_job_aggregate(where: {deleted_at: {_is_null: true}, date: {_eq: $date}}) {
    aggregate {
      total: count
    }
  }
  line_job(where: {deleted_at: {_is_null: true}, date: {_eq: $date}}) {
    id
    description
    code
    line_id
    leader_id
    customer_id
    item_id
    workers_estimated_qty
    code
    line {
      location {
        id
        name
      }
    }
    customer {
      name
    }
    date
    shift
    items_qty
    state
    item {
      id
      description
      number
      material_price
      sales_price
    }
    line {
      number
    }
    user {
      first_name
      last_name
    }
  }
}
`;

export const LIST_ALL_EQUIPMENT = gql`
  query ListAllEquiment {
    equipment(where: {deleted_at: {_is_null: true}}) {
      id
      name
    }
  }
`;

export const LIST_ALL_JOBTYPES = gql`
  query ListAllJobType {
    job_type(where: {deleted_at: {_is_null: true}}) {
      id
      name
    }
  }
`;

export const LIST_ALL_PALLETS = gql`
  query ListAllPallets {
    pallet(where: {deleted_at: {_is_null: true}}) {
      id
      description
    }
  }
`;


export const MOVE_WORKERS_TO_LINE_JOB =  gql`
  mutation moveWorkersToline($to: Int!, $from: Int!, $stop_time: timestamp, $moved_workers: [line_job_worker_insert_input!]!, $moved_ids: [Int!]!, $newTimelogs: [timelog_insert_input!]!, $total_workers: Int!, $workers_to_rest: Int!) {
    delete_line_job_worker(where: {woker_id: {_in: $moved_ids}, line_job_id: {_eq: $to}}) {
      affected_rows
    }
    insert_line_job_worker(objects: $moved_workers) {
      returning {
        id
        line_job_id
        woker_id
        worker {
          first_name
          last_name
        }
      }
    }
    update_timelog(_set: {stop_type: "SOFT STOP", stop_time: $stop_time}, where: {worker_id: {_in: $moved_ids}, line_job_id: {_eq: $from}, stop_time: {_is_null: true}}) {
      affected_rows
    }
    insert_timelog(objects: $newTimelogs) {
      returning {
        worker_id
        line_job_id
      }
    }
    update_line_job(where: { id: {_eq: $from }},  _inc: {workers_active: $workers_to_rest}) {
      returning {
        id
        workers_active
      }
    }
    update_line_job_by_pk(pk_columns: {id: $to}, _inc: {workers_qty: $total_workers, workers_active:$total_workers }) {
      id
    }
  }
`;

export const STOP_WORKERS_FROM_JOB = gql`
  mutation moveWorkersToline($from: Int!, $stop_type: String, $stop_time: timestamp, $workers: [Int!]!, $lunch_time: Int, $workers_to_rest: Int!) {
    update_timelog(_set: {stop_type: $stop_type, stop_time: $stop_time, lunch_time: $lunch_time}, where: {worker_id: {_in: $workers}, line_job_id: {_eq: $from}, stop_time: {_is_null: true}}) {
      affected_rows
      returning {
        id
        line_id
        line_job_id
        date
        shift
        start_time
        start_type
        state
        stop_time
        stop_type
        worker_id
        checkout_comment
      }
    }
    update_line_job_by_pk(pk_columns: {id: $from}, _inc: { workers_active: $workers_to_rest }) {
      id
    }
  }
`;

export const START_WORKERS_FOR_JOB = gql`
  mutation moveWorkersToline($moved_workers: [line_job_worker_insert_input!]!, $newTimelogs: [timelog_insert_input!]!, $to: Int!, $total_workers: Int!) {
    insert_line_job_worker(objects: $moved_workers) {
      returning {
        id
        line_job_id
        woker_id
        worker {
          first_name
          last_name
        }
      }
    }
    insert_timelog(objects: $newTimelogs) {
      returning {
        worker_id
        line_job_id
      }
    }
    update_line_job_by_pk(pk_columns: {id: $to}, _inc: {workers_qty: $total_workers, workers_active: $total_workers }) {
      id
    }
  }
`;

export const LIST_JOBS_UNITS = gql`
  query ListJobsUnits {
    unit {
      value
      comment
    }
  }
`;


export const ASSIGN_LEADER = gql`
  mutation UpdateLineJob($where: line_job_bool_exp!, $object: line_job_set_input) {
    update_line_job(_set: $object, where: $where) {
      affected_rows
    }
  }
`;



export const ALL_LINES_JOB_BY_LOCATION = gql`
  query GetAllLinesJobByLocation($location_id: Int!, $date: date!) {
    line_job(
      where: {
        deleted_at: { _is_null: true }
        line: { location_id: { _eq: $location_id }, is_add_on: { _eq: false } }
        date: { _eq: $date }
      }
    ) {
      code
      customer_id
      date
      deleted_at
      description
      id
      shift
      item {
        description
        number
      }
      item_id
      item_material_price
      item_sales_price
      items_qty
      leader_id
      start_time
      stop_time
      customer {
        name
        id
        location_id
      }
      line {
        id
        number
      }
    }
  }
`;

export const GET_ACTIVE_LINE_JOB_BY_ID = gql`
  query GetActiveLineJobByID($id: Int!) {
    line_job(where: {id: {_eq: $id}, state: {_in: [CREATED, STARTED]}}) {
      id
      line_id
      leader_id
      customer_id
      item_id
      shift
      items_qty
      date
      description
      workers_estimated_qty
      code
      start_time
      customer {
        id
        name
      }
      line {
        id
        number
        location {
          id
          name
        }
        is_misc
      }
      item {
        description
        number
      }
    }
  }
`;


export const UPDATE_JUST_LINE_JOB = gql`
  mutation UpdateJustLineJob($id: Int!, $object: line_job_set_input) {
    update_line_job_by_pk(pk_columns: {id: $id}, _set: $object) {
      id
      line_id
      leader_id
      customer_id
      item_id
      shift
      items_qty
      date
      notes
    }
  }
`;


export const LIST_LINE_JOBS_PER_QUERY = gql `
  query GetLineJobs($where: line_job_bool_exp!, $order: line_job_order_by!) {
    line_job(where: $where, order_by: [$order]) {
      id
      description
      code
      customer {
        name
      }
      date
      shift
      start_time
      job_start
      stop_time
      item {
        id
        number
      }
    }
  }
`;

export const LIST_LINE_JOBS_FOR_BULK = gql `
  query GetLineJobsBulk($where: line_job_bool_exp!, $pageSize: Int, $offset: Int, $order: line_job_order_by!) {
    line_job_aggregate(where: $where) {
      aggregate {
        total: count
      }
    }
    line_job(where: $where, limit: $pageSize, offset: $offset, order_by: [$order]) {
      id
      description
      code
      customer {
        name
      }
      date
      shift
      items_qty
      items_estimated_qty
      workers_estimated_qty
      workers_qty
      workers_active
      state
      item {
        id
        number
        material_price
        sales_price
        description
      }
      line {
        number
        location {
          name
        }
        is_add_on
      }
      user {
        first_name
        last_name
      }
    }
  }
`;

export const GET_STATES = gql`
  query GetStates {
    state(where: {value: {_nin: [STOPPED, CLOSED]}}) {
      value
    }
  }
`;


export const UPDATE_PRODUCTION_INFO = gql `
  mutation UpdateProductionInfo($id: Int!, $object: line_job_set_input!) {
    update_line_job_by_pk(pk_columns: {id: $id}, _set: $object) {
      id
    }
  }
`;

export const IMPORT_DATA = gql`
  mutation ImportData($filename: String!) {
    importData(arg1: {filename: $filename})
  }
`;


export const STOP_WORKER_AND_DECREASE_COUNT = gql`
  mutation stopWorkerAndDrecreaseCount($from: Int!, $stop_type: String, $stop_time: timestamp, $workers: [Int!]!, $lunch_time: Int) {
    update_timelog(_set: {stop_type: $stop_type, stop_time: $stop_time, lunch_time: $lunch_time}, where: {worker_id: {_in: $workers}, line_job_id: {_eq: $from}, stop_time: {_is_null: true}}) {
      affected_rows
      returning {
        id
        line_id
        line_job_id
        date
        shift
        start_time
        start_type
        state
        stop_time
        stop_type
        worker_id
        checkout_comment
      }
    }
    update_line_job_by_pk(pk_columns: {id: $from}, _inc: {workers_qty: -1, workers_active: -1 }) {
      id
      workers_active
      workers_qty
    }
  }
`;

export const CHECK_BUSY_WORKERS = gql`
  query CheckForBusyWorkers($workers: [Int!]!) {
    worker(where: {id: {_in: $workers}, timelogs: {stop_time: {_is_null: true}, deleted_at: {_is_null: true}}}) {
      id
    }
  }
`;