import { useExpanded, usePagination, useSortBy, useTable } from "react-table";

import EmptyMessage from "../EmptyMessage";
import PropTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";
import styles from "./Table.module.scss";
import { useTranslation } from "next-i18next";
import { getPaginationOptions } from "../../utils/array-utils";

const EmbeddedInnerTable = ({
  loading,
  columns,
  data,
  customCellStyles = [],
  pageSize = 10,
  emptyMessage = "",
  width,
  totalActions = [],
}) => {
  const { t } = useTranslation();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: pageSize,
      },
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  const paginationOptions = getPaginationOptions(pageCount, pageIndex, true);

  return (
    <div className={styles.body}>
      <table {...getTableProps()} className={styles.table}>
        <TableHeader
          customCellStyles={customCellStyles}
          headerGroups={headerGroups}
          totalActions={totalActions}
        />

        {!!data.length && !loading && (
          <TableBody
            page={page}
            prepareRow={prepareRow}
            totalActions={totalActions}
            customCellStyles={customCellStyles}
            width={width}
            {...getTableBodyProps()}
          />
        )}
      </table>

      {!data.length && !loading && (
        <div
          style={{ height: 256 }}
          className={"border-bottom border-secundary"}
        >
          <EmptyMessage
            principal={t("views.table.noData")}
            secondary={emptyMessage || t("views.table.noDataMessage")}
            justSecundary={!!emptyMessage}
          />
        </div>
      )}

      {!!data.length && pageCount > 1 && !loading && (
        <div className={`pagination ${styles["pagination-container"]}`}>
          <button
            type="button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className={styles["pagination-option"]}
          >
            {t("views.table.previous")}
          </button>{" "}
          {paginationOptions.map((option) =>
            option === "first_spacer" || option === "second_spacer" ? (
              <div
                className="d-flex align-items-center justify-content-center mx-2"
                key={option}
              >
                <p className="mb-0">{"..."}</p>
              </div>
            ) : (
              <button
                type="button"
                key={option}
                onClick={() => gotoPage(option)}
                className={`${styles["page-option"]} ${
                  pageIndex === option ? styles["selected-option"] : ""
                }`}
              >
                {option + 1}
              </button>
            )
          )}
          <button
            type="button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className={styles["pagination-option"]}
          >
            {t("views.table.next")}
          </button>{" "}
        </div>
      )}
      {loading && (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: 560 }}
        >
          <Spinner animation="border" />{" "}
        </div>
      )}
    </div>
  );
};

EmbeddedInnerTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.any.isRequired,
      accessor: PropTypes.string.isRequired,
    }).isRequired
  ),
  data: PropTypes.array.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  customCellStyles: PropTypes.array,
  pageSize: PropTypes.number.isRequired,
  emptyMessage: PropTypes.string,
};

export default EmbeddedInnerTable;
